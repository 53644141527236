export interface ShareOptions {
  title: string;
  text: string;
  url: string;
}

export type ShareAPI = (options: ShareOptions) => Promise<void>;

export default (() => {
  if ('share' in navigator) {
    return (navigator as any).share.bind(navigator);
  }
})() as ShareAPI | undefined;
