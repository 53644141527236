import * as React from 'react';

export interface Dimensions {
  width: number;
  height: number;
}

export const useWindowDimensions = (): Dimensions => {
  const [dimensions, setDimensions] = React.useState<Dimensions>({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const update = React.useCallback(() => {
    setDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  }, [setDimensions]);

  React.useEffect(() => {
    window.addEventListener('resize', update, false);

    return () => window.removeEventListener('resize', update, false);
  }, [update]);

  return dimensions;
};

export default (ref: React.MutableRefObject<Element | null>): Dimensions => {
  const [calledOnce, setCalledOnce] = React.useState(false);
  const [dimensions, setDimensions] = React.useState<Dimensions>({
    width: 0,
    height: 0,
  });

  const update = React.useCallback(() => {
    if (ref.current) {
      const {width, height} = ref.current.getBoundingClientRect();
      if (
        dimensions.width !== width ||
        dimensions.height !== height ||
        !calledOnce
      ) {
        if (!calledOnce) {
          setCalledOnce(true);
        }
        setDimensions({width, height});
      }
    }
  }, [
    ref,
    calledOnce,
    setCalledOnce,
    dimensions.width,
    dimensions.height,
    setDimensions,
  ]);

  React.useLayoutEffect(() => {
    if (!calledOnce) {
      update();
    }
  }, [calledOnce, update]);

  React.useEffect(() => {
    window.addEventListener('resize', update, false);

    return () => window.removeEventListener('resize', update, false);
  }, [update]);

  return dimensions;
};
