import * as React from 'react';
import distanceInWordsToNow from 'date-fns/distance_in_words_to_now';
import isPast from 'date-fns/is_past';
import {getEndOfDay} from './Events';

const getActive = (date: Date): boolean =>
  Date.now() >= date.getTime() && Date.now() <= getEndOfDay(date).getTime();

const getCountText = (date: Date, active: boolean): string =>
  active
    ? 'Active now'
    : isPast(getEndOfDay(date))
    ? 'Past event'
    : distanceInWordsToNow(date);

export interface CountdownInfo {
  active: boolean;
  text: string;
}

const getInfo = (date: Date): CountdownInfo => {
  const active = getActive(date);
  const text = getCountText(date, active);

  return {active, text};
};

export default (date: Date) => {
  const [info, setInfo] = React.useState<CountdownInfo>(getInfo(date));

  React.useEffect(() => {
    setInfo(getInfo(date));

    const timer = setInterval(() => {
      setInfo(getInfo(date));
    }, 60e3);

    return () => clearInterval(timer);
  }, [date, setInfo]);

  return info;
};
