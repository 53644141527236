import * as React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

import './LoadingOverlay.css';

const LoadingOverlay: React.FC = () => (
  <div className="LoadingOverlay">
    <CircularProgress />
  </div>
);

export default LoadingOverlay;
