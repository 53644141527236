interface CacheEntry<T> {
  value: T | null;
  promise: Promise<T>;
}

export type KeyHasher<T extends any[]> = (...args: T) => string;

const defaultKeyHasher = <T extends any[]>(...args: T): string =>
  args.join('.');

export default <A extends any[], R>(
  func: (...args: A) => Promise<R>,
  keyHasher: KeyHasher<A> = defaultKeyHasher,
): ((...args: A) => Promise<R>) => {
  const store = new Map<string, CacheEntry<R>>();

  return async (...args: A): Promise<R> => {
    const key = keyHasher(...args);
    const entry = store.get(key);

    if (entry) {
      if (entry.value) return entry.value;
      return entry.promise;
    }

    const newEntry: CacheEntry<R> = {
      value: null,
      promise: func(...args),
    };

    store.set(key, newEntry);
    newEntry.value = await newEntry.promise;

    return newEntry.value;
  };
};
