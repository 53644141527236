import * as React from 'react';
import Fab, {FabProps} from '@material-ui/core/Fab';
import ShareIcon from '@material-ui/icons/Share';

import share, {ShareOptions} from '../lib/share';

export interface Props extends FabProps {
  share: ShareOptions;
}

const ShareButton: React.FC<Props> = ({share: {title, text, url}, ...rest}) => {
  const handleShare = React.useCallback(() => {
    share &&
      share({title, text, url}).catch(err => {
        console.error('Failed to share: ', err);
      });
  }, [title, text, url]);

  if (!share) {
    return null;
  }

  return (
    <Fab {...rest} onClick={handleShare}>
      <ShareIcon />
    </Fab>
  );
};

export default ShareButton;
