import * as React from 'react';

export default <T>(value: T): T => {
  const ref = React.useRef(value);

  React.useEffect(() => {
    ref.current = value;
  });

  return ref.current;
};
