import * as parse from 'papaparse';

const getSheetURI = (sheetID: string): string =>
  `https://docs.google.com/spreadsheets/d/${sheetID}/export?format=csv`;

export const getSheet = async (sheetID: string): Promise<string[][]> => {
  const req = await fetch(getSheetURI(sheetID));
  const csv = await req.text();

  const result = parse.parse(csv, {});

  return result.data;
};
