import * as React from 'react';
import {EventEmitter} from 'events';

const emitter = new EventEmitter();

window.addEventListener('popstate', () => {
  emitter.emit('change');
});

class Router {
  public readonly path: string;

  public constructor(path: string) {
    this.path = path;
  }

  public goTo(path: string) {
    window.history.pushState(null, '', path);
    emitter.emit('change');
  }

  public goBack() {
    window.history.back();
    emitter.emit('change');
  }

  public replace(path: string) {
    window.history.replaceState(null, '', path);
    emitter.emit('change');
  }
}

export default (): Router => {
  const [router, setRouter] = React.useState(
    new Router(window.location.pathname),
  );

  const listener = React.useCallback(() => {
    if (window.location.pathname !== router.path) {
      setRouter(new Router(window.location.pathname));
    }
  }, [router, setRouter]);

  React.useEffect(() => {
    emitter.on('change', listener);

    return () => {
      emitter.off('change', listener);
    };
  }, [listener]);

  return router;
};
