import * as React from 'react';

import * as GoogleSheet from './GoogleSheet';
import asyncMemo from './asyncMemo';
import binarySearch from './binarySearch';

type Hours = [number, number, number, number];

export interface Coordinates {
  latitude: number;
  longitude: number;
}

export interface Event {
  date: Date;
  name: string;
  address: string;
  coordinates: Coordinates;
}

const SHEET_ID = '1nez8C8m51UxDoEAxMf1I9uoSNyfVLPTi9m-jgD24faA';

const DEFAULT_HOURS: Hours = [19, 0, 0, 0];

const parseHours = (time: string): Hours => {
  console.log('time: ', time);
  const match = /(\d+):(\d+)(:\d+)?\s*(AM|PM)/.exec(time);

  if (!match) {
    return DEFAULT_HOURS;
  }

  const [, hours, mins, _, meridiem] = match;

  return [
    parseInt(hours, 10) + (meridiem === 'PM' ? 12 : 0),
    parseInt(mins, 10),
    0,
    0,
  ];
};

const isNonNullString = (str: string | null | undefined): boolean =>
  str !== '' && str != null;

const isValidRow = (row: string[]): boolean => {
  return (
    /\d+\/\d+\/\d+/.test(row[0]) &&
    /(\d+):(\d+)(:\d+)?\s*(AM|PM)/.test(row[1]) &&
    isNonNullString(row[2]) &&
    isNonNullString(row[3]) &&
    /\d+/.test(row[4]) &&
    /\d+/.test(row[5])
  );
};

const parseEvent = (row: string[]): Event => {
  const date = new Date(row[0]);
  date.setHours(...parseHours(row[1]));

  return {
    date,
    name: row[2],
    address: row[3],
    coordinates: {
      latitude: parseFloat(row[4]),
      longitude: parseFloat(row[5]),
    },
  };
};

export const getEndOfDay = (date: Date): Date => {
  const ret = new Date(date.getTime());
  ret.setHours(23, 59, 59, 999);

  return ret;
};

export const getEvents = asyncMemo(
  async (): Promise<Event[]> => {
    const data = await GoogleSheet.getSheet(SHEET_ID);

    return data
      .slice(1)
      .filter(isValidRow)
      .map(parseEvent);
  },
);

export const searchForEvent = (events: Event[], date: Date): Event | null => {
  if (!events.length) {
    return null;
  }

  const time = date.getTime();

  if (time < events[0].date.getTime()) return events[0];
  if (time >= events[events.length - 1].date.getTime())
    return events[events.length - 1];

  const search = binarySearch(events, (event, i) => {
    const endOfDay = getEndOfDay(event.date);
    const past = i - 1 >= 0 ? getEndOfDay(events[i - 1].date).getTime() : time;

    if (time >= past && time <= endOfDay.getTime()) {
      return 0;
    }

    if (time > endOfDay.getTime()) {
      return 1;
    }

    return -1;
  });

  return search!;
};

export const getCurrentEvent = asyncMemo(
  async (): Promise<Event | null> => {
    return searchForEvent(await getEvents(), new Date());
  },
);

export const useEvents = (): [Event[], boolean, Error | null] => {
  const [events, setEvents] = React.useState<Event[]>([]);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [error, setError] = React.useState<Error | null>(null);

  React.useEffect(() => {
    setLoading(true);
    getEvents()
      .then(events => {
        setLoading(false);
        setEvents(events);
      })
      .catch(err => {
        setLoading(false);
        setError(err);
      });
  }, []);

  return [events, loading, error];
};

export const useCurrentEvent = (): [Event | null, boolean, Error | null] => {
  const [events, loading, error] = useEvents();
  const event = React.useMemo(() => searchForEvent(events, new Date()), [
    events,
  ]);

  return [event, loading, error];
};
