import React from 'react';
import {ThemeProvider} from '@material-ui/styles';
import Fab from '@material-ui/core/Fab';
import ListIcon from '@material-ui/icons/List';
import CloseIcon from '@material-ui/icons/Close';

import theme from './theme';
import * as Events from './lib/Events';
import useRouter from './lib/useRouter';
import EventView from './components/EventView';
import EventsList from './components/EventsList';
import LoadingOverlay from './components/LoadingOverlay';

import './App.css';

const App: React.FC = () => {
  const router = useRouter();
  const [activeEvent, setActiveEvent] = React.useState<Events.Event | null>(
    null,
  );
  const [event, loading] = Events.useCurrentEvent();
  const viewEvent = activeEvent ? activeEvent : event;
  const handleSelectEvent = React.useCallback(
    (event: Events.Event) => {
      router.goTo('/');
      setActiveEvent(event);
    },
    [router, setActiveEvent],
  );
  const showEventsList = router.path === '/events';

  return (
    <ThemeProvider theme={theme}>
      <Fab
        style={{position: 'fixed', top: '16px', right: '16px', zIndex: 20000}}
        color={showEventsList ? 'secondary' : 'primary'}
        onClick={() => router.goTo(showEventsList ? '/' : '/events')}>
        {showEventsList ? <CloseIcon /> : <ListIcon />}
      </Fab>
      {showEventsList && <EventsList onSelectEvent={handleSelectEvent} />}
      <div>
        {loading && <LoadingOverlay />}
        {viewEvent && <EventView event={viewEvent} />}
      </div>
    </ThemeProvider>
  );
};

export default App;
