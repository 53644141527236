import * as React from 'react';
import Chip from '@material-ui/core/Chip';
import TimeIcon from '@material-ui/icons/Timelapse';

export interface Props {
  active: boolean;
  text: string;
}

const Countdown: React.FC<Props> = ({active, text}) => (
  <Chip
    icon={<TimeIcon />}
    label={text}
    color={active ? 'secondary' : 'default'}
  />
);

export default Countdown;
