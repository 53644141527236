import * as React from 'react';
import MapGL, {ViewState, Marker} from 'react-map-gl';
import LocationIcon from '@material-ui/icons/LocationOn';
import Box from '@material-ui/core/Box';
import Fab from '@material-ui/core/Fab';
import DirectionsIcon from '@material-ui/icons/Directions';

import ShareButton from './ShareButton';
import EventCardInfo from './EventCardInfo';
import Beacon from './Beacon';
import {Event} from '../lib/Events';
import useDimensions from '../lib/useDimensions';
import {API_KEY} from '../config/mapbox';
import formatEventDate from '../lib/formatEventDate';
import openNativeMap from '../lib/openNativeMap';
import usePrevious from '../lib/usePrevious';

const easeOutExpo = (t: number): number =>
  t === 1 ? 1 : -Math.pow(2, -10 * t) + 1;

export interface Props {
  style?: React.HTMLProps<HTMLDivElement>['style'];
  event: Event;
}

const EventView = ({event}: Props) => {
  const {name, date, address, coordinates} = event;
  const prevEvent = usePrevious(event);
  const [viewState, setViewState] = React.useState<ViewState>({
    ...coordinates,
    zoom: 12,
  });
  const container = React.useRef<HTMLDivElement>(null);
  const info = React.useRef<HTMLDivElement>(null);
  const containerDimensions = useDimensions(container);
  const infoDimensions = useDimensions(info);
  const formattedDate = React.useMemo(() => formatEventDate(date), [date]);
  const handleGetDirections = React.useCallback(() => {
    openNativeMap(address);
  }, [address]);

  React.useEffect(() => {
    const {coordinates} = event;
    if (
      prevEvent.coordinates.latitude !== coordinates.latitude ||
      prevEvent.coordinates.longitude !== coordinates.longitude
    ) {
      setViewState({
        ...viewState,
        ...coordinates,
      });
    }
  }, [event, prevEvent, viewState]);

  return (
    <div
      ref={container}
      style={{
        width: `100%`,
        height: `${window.innerHeight}px`,
      }}>
      <MapGL
        {...viewState}
        transitionDuration={1500}
        transitionEasing={easeOutExpo}
        width={containerDimensions.width}
        height={containerDimensions.height - infoDimensions.height}
        mapStyle="mapbox://styles/mapbox/streets-v11"
        mapboxApiAccessToken={API_KEY}
        onViewportChange={viewState => setViewState(viewState)}>
        <Marker {...coordinates}>
          <Beacon>
            <LocationIcon color="secondary" style={{zIndex: 2000}} />
          </Beacon>
        </Marker>
      </MapGL>
      <div style={{position: 'relative'}} ref={info}>
        <ShareButton
          share={{
            title: `Come fellow ship with us!`,
            text: `We will be meeting at ${name} on ${formattedDate}. ${name} is located at ${address}`,
            url: window.location.href,
          }}
          style={{position: 'absolute', right: '16px', top: '-28px'}}
          color="primary"
        />
        <EventCardInfo event={event}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{marginTop: '16px'}}>
            <Fab
              variant="extended"
              color="secondary"
              onClick={handleGetDirections}>
              <DirectionsIcon style={{marginRight: '8px'}} />
              Get Directions
            </Fab>
          </Box>
        </EventCardInfo>
      </div>
    </div>
  );
};

export default EventView;
