export type Comparator<T> = (item: T, index: number) => -1 | 0 | 1;

export default <T>(arr: T[], comparator: Comparator<T>): T | undefined => {
  let l = 0;
  let r = arr.length;

  while (l < r) {
    const mid = l + Math.floor((r - l) / 2);
    const value = comparator(arr[mid], mid);

    if (value === 0) {
      return arr[mid];
    }
    if (value < 0) {
      r = mid;
    } else {
      l = mid + 1;
    }
  }
};
