import React from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';

import * as Events from '../lib/Events';
import EventCardInfo from './EventCardInfo';
import {useWindowDimensions} from '../lib/useDimensions';

export interface Props {
  onSelectEvent?: (event: Events.Event) => void;
}

const EventsList: React.FC<Props> = ({onSelectEvent}) => {
  const [events] = Events.useEvents();
  const {width, height} = useWindowDimensions();

  return (
    <div
      className="fade-in"
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: `${width}px`,
        height: `${height}px`,
        zIndex: 10000,

        backgroundColor: 'rgba(0, 0, 0, 0.7)',
      }}>
      <div
        className="touch-scrolling"
        style={{
          marginTop: '72px',
          overflowY: 'auto',
          boxSizing: 'border-box',
          padding: '16px',
          width: `${width}px`,
          height: `${height - 72}px`,
        }}>
        {events.map((event, i) => (
          <Card key={i} style={{marginBottom: '16px'}}>
            <EventCardInfo event={event}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                style={{marginTop: '16px'}}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => onSelectEvent && onSelectEvent(event)}>
                  View
                </Button>
              </Box>
            </EventCardInfo>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default EventsList;
