import * as React from 'react';
import copyToClipboard from 'copy-to-clipboard';
import Box from '@material-ui/core/Box';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Snackbar from '@material-ui/core/Snackbar';

import Countdown from './Countdown';
import {Event} from '../lib/Events';
import useCountdown from '../lib/useCountdown';
import formatEventDate from '../lib/formatEventDate';

export interface Props {
  event: Event;
  children?: React.ReactNode;
}

const EventCardInfo: React.FC<Props> = ({event, children}) => {
  const {name, date, address} = event;
  const countdownInfo = useCountdown(date);
  const formattedDate = React.useMemo(() => formatEventDate(date), [date]);
  const [copiedAddress, setCopiedAddress] = React.useState(false);

  const copyAddress = React.useCallback(() => {
    setCopiedAddress(copyToClipboard(address));
  }, [address, setCopiedAddress]);
  return (
    <CardContent>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start">
        <Typography gutterBottom variant="h5" component="h2">
          {name}
        </Typography>
        <Countdown {...countdownInfo} />
      </Box>
      <Typography variant="subtitle1" color="textSecondary" gutterBottom>
        {formattedDate}
      </Typography>
      <Typography
        variant="body2"
        color="textSecondary"
        component="p"
        onClick={copyAddress}>
        {address}
      </Typography>
      {children}
      <Snackbar
        open={copiedAddress}
        anchorOrigin={{vertical: 'top', horizontal: 'center'}}
        onClose={() => setCopiedAddress(false)}
        autoHideDuration={3000}
        message="Copied Address"
      />
    </CardContent>
  );
};

export default EventCardInfo;
